import React from "react"

import { graphql, useStaticQuery } from "gatsby"

// Component imports

import Section from "./Section"
import Text from "./Text"
import BackgroundImage from "gatsby-background-image"
import { Fade } from "react-awesome-reveal"

interface HeroProps {
  title?: string
  subtitle?: string
  children?: string | JSX.Element
}

const Hero: React.FC<HeroProps> = ({ title, subtitle, children }) => {
  // Queries
  const data = useStaticQuery(graphql`
    {
      background: file(relativePath: { eq: "hero.png" }) {
        childImageSharp {
          fluid(maxWidth: 1920, quality: 95) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  return (
    <BackgroundImage fluid={data.background.childImageSharp.fluid}>
      <Section className="flex flex-col justify-center h-screen md:h-75vh">
        <Fade direction="up" duration={500} triggerOnce delay={100}>
          <Text
            styleTo="h1"
            tag="h1"
            className={`xxl:w-8/12 xl:w-10/12 text-left ${
              subtitle && "mb-6 xl:mb-2"
            }`}
          >
            {title}
            {children}
          </Text>
        </Fade>

        {subtitle && (
          <Fade direction="up" duration={500} delay={500} triggerOnce>
            <Text
              styleTo="h3"
              tag="h2"
              className="text-center opacity-50 xxl:w-8/12 xl:w-10/12 md:text-left"
            >
              {subtitle}
            </Text>
          </Fade>
        )}
      </Section>
    </BackgroundImage>
  )
}

export default Hero
